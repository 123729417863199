.photo-gallery .image-container {
    position: relative;
}

.photo-gallery img {
    transition: transform 0.3s ease;
}

.photo-gallery img:hover {
    transform: scale(1.05);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-image {
    max-width: 100%;
    max-height: 70vh;
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    gap: 20px;
}

.download-btn,
.share-btn {
    background-color: rgba(80, 165, 177, 0.9); /* primary color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-btn:hover,
.share-btn:hover {
    background-color: rgba(80, 165, 177, 0.7); /* Slightly lighter primary color */
}
