html, body, #root {
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: 'Daggersquare';
  src: url('./assets/fonts/DAGGERSQUARE.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  padding-top: 4rem; /* Altezza approssimativa della navbar */
  margin: 0;
  font-family: Daggersquare;
}

#root {
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}
