.landing-page-container {
    position: relative;
    height: 80vh;
    width: 100%;
    background-size: cover; /* Mantiene la copertura completa */
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4); /* Semi-trasparente */
}

.text-center {
    text-align: center;
    color: white;
}

.landing-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    font-style: italic;
}

.landing-subtitle {
    font-size: 1.5rem;
    margin: 0;
    font-style: italic;
}
