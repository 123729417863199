.cta-container {
    text-align: center;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-trasparente per risaltare */
    color: white;
    margin: 2rem auto 0; /* Spazio intorno al CTA */
    width: 90%; /* Adatta la larghezza per dispositivi mobili */
    max-width: 100%; /* Limita la larghezza massima */
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); /* Leggera ombra per effetto elevazione */
    padding-bottom: 4rem;
}

.cta-title {
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 1rem;
}

.cta-description {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.cta-button {
    padding: 0.75rem 1.5rem;
    background-color: rgba(80,165,177,0.9); /* Colore primario */
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #2d7a80;
    transform: scale(1.05); /* Leggera espansione al passaggio del mouse */
}
