@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
}

header nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
