.carousel-container {
    position: relative;
    overflow: hidden;
    padding-top: 50px; /* Add space for top shadow */
    padding-bottom: 50px; /* Add space for bottom shadow */
}

.carousel-shadow-left,
.carousel-shadow-right,
.carousel-shadow-top,
.carousel-shadow-bottom {
    position: absolute;
    z-index: 1;
}

.carousel-shadow-left,
.carousel-shadow-right {
    top: 0;
    bottom: 0;
    width: 50px;
}
/*
.carousel-shadow-left {
    left: 0;
    background: linear-gradient(to right, rgba(241,96,13,0.3), rgba(0, 0, 0, 0));
}

.carousel-shadow-right {
    right: 0;
    background: linear-gradient(to left, rgba(241,96,13,0.3), rgba(0, 0, 0, 0));
}
*/
.carousel-shadow-top,
.carousel-shadow-bottom {
    left: 0;
    right: 0;
    height: 50px;
}

.carousel-shadow-top {
    top: 0;
    background: linear-gradient(to bottom, rgba(241,96,13,0.3), rgba(0, 0, 0, 0));
}

.carousel-shadow-bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(241,96,13,0.3), rgba(0, 0, 0, 0));
}

.custom-audio-player {
    width: 100%;
    background-color: rgba(80,165,177,0); /* Colore primario */
    border-radius: 5px;
    padding: 5px;
    outline: none;
}

.custom-audio-player::-webkit-media-controls-panel {
    background-color: rgba(80,165,177,0.6); /* Colore primario */
    border-radius: 5px;
}

.custom-audio-player::-webkit-media-controls-play-button,
.custom-audio-player::-webkit-media-controls-current-time-display,
.custom-audio-player::-webkit-media-controls-time-remaining-display,
.custom-audio-player::-webkit-media-controls-seek-back-button,
.custom-audio-player::-webkit-media-controls-seek-forward-button,
.custom-audio-player::-webkit-media-controls-timeline,
.custom-audio-player::-webkit-media-controls-volume-slider {
    color: white;
}

.custom-audio-player::-webkit-media-controls-mute-button,
.custom-audio-player::-webkit-media-controls-fullscreen-button {
    filter: brightness(0) invert(1); /* Cambia il colore delle icone */
}

.custom-audio-player::-webkit-slider-thumb {
    background-color: #2d7a80; /* Colore del thumb slider */
}

.custom-audio-player::-webkit-slider-runnable-track {
    background-color: rgba(80,165,177,0.5); /* Colore della traccia */
}
